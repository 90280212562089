<script>
  import { useCookies } from "vue3-cookies";
  import { useStore } from 'vuex'
  import { mapActions} from "vuex";


  import "@vueform/multiselect/themes/default.css";

  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';

  import serverConfig from '@/helpers/config';
  
  export default {
    name:'brandModel',
    props: [
    'ID',
    'data',
    ],    
    setup() {
        const { cookies } = useCookies();
        const store = useStore();
        console.log(store)
        let tk = 'Bearer '+cookies.get('tk')
        return { cookies, tk};
    },
    page: {
      title: "Gestione Marche",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        dataEdit:{},
        item:{
            name:'',
            content:'',
            image:'',
            lang:'',
            publish:'',
            on_the_homepage:'no',
            status:'init',
        },
        items: [
          {
            text: "Gestione Marche",
            active: true,
          },
        ],

      };
    },
    computed: {
      
    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    beforeMount() {
     
    },
    methods: {
      ...mapActions('auth', ['refreshToken','clearSessionStorage']),

      gotTo(name,id) {
        document.getElementById("edtclosemodal").click();
        this.$router.push({name:name, params:{id: id}})
      },

      editdata(data) {
        let result = this.itemList.findIndex(
          (index) => index._id == data._id
        );
       
       
        document.getElementById("edtidfield1").value =
          this.itemList[result]._id;
         this.dataEdit = this.itemList[result]
      },
      initItem(){
        axios.post(`${this.UrlServer}brands/register`,this.item, {headers: { authorization:this.tk}} )
          .then(()=>{
             this.sortRes = 'desc' 
             this.getList()
          })
          .catch((error)=>{
            if (error == "Error: Request failed with status code 401")
            {
               this.alertSessionExp()
            } else {
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }
             
          });
      },
      updatedata() {
       
        axios.put(`${this.UrlServer}brands/${this.dataEdit._id}`, this.dataEdit, 
         {headers: { authorization:this.tk}} )
          .then(() => {
            document.getElementById("edtclosemodal").click();
            Swal.fire(  this.$t("t-msg-updated") , this.$t("t-msg-updated-content"), "success");
          }).catch((error) => {
            if (error == "Error: Request failed with status code 401")
            {
               this.alertSessionExp()
            } else {
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }

          });
      },

      async alertSessionExp() {
        this.clearSessionStorage()

      },


    },
    mounted() {
        this.dataEdit = this.data
    },
    components: {

    },
  };
</script>

<template>
    <a :href="'#editmodal_'+dataEdit._id" data-bs-toggle="modal" class="text-primary d-inline-block edit-item-btn">
        <i class="ri-pencil-fill fs-16"></i>
    </a>
    <div class="modal fade" :id="'editmodal_'+dataEdit._id" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Aggiorna anagrafica Marca
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                      id="close-modal"></button>
                </div>
                <form action="#" id="addform">
                    <div class="modal-body">
                      <input type="hidden" id="id-field" />
                      <div class="mb-3" id="modal-id" style="display: none">
                        <label :for="'id-field1_'+dataEdit._id" class="form-label">ID</label>
                        <input type="text" :id="'id-field1_'+dataEdit._id" class="form-control" placeholder="ID" readonly />
                      </div>
                      <div class="mb-3">
                        <label for="ragSoc" class="form-label">Codice Marca</label>
                        <input type="text"  v-model="dataEdit.code" class="form-control" placeholder="Inserisci Codice"
                          required />
                      </div>
                      <div class="mb-3">
                        <label for="partitaIva" class="form-label">Nome</label>
                        <input type="text" v-model="dataEdit.name" class="form-control" placeholder="Inserisci Nome Marca"
                          required />
                      </div>
                      <div class="mb-3">
                        <label for="customername-field" class="form-label">Descrizione</label>
                        <textarea type="text"  v-model="dataEdit.content" class="form-control" placeholder="Inserisci Descrizione"
                          required ></textarea>
                      </div> 
                      <div class="mb-3">
                        <a href="javascript:void(0)" @click="gotTo('brands-detail', dataEdit._id)">
                            >> Impostazioni avanzate
                        </a>               
                      </div>

                    </div>
                    <div class="modal-footer">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="button" id="edtclosemodal" class="btn btn-light" data-bs-dismiss="modal">
                          Chiudi
                        </button>
                        <button type="button" class="btn btn-success" @click="updatedata">
                          Aggiorna
                        </button>
                      </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>